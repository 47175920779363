import React, { createContext, useReducer } from 'react';
import { getPCSstatusByLocation } from '../utils/utils';
import MetricsModule from 'src/utils/MetricsModule';

export const MetricsContext = createContext();

function transformPayloadToBlob(payload) {
  return new Blob([JSON.stringify(payload)], {
    type: 'application/json',
  });
}

/*
 * Note:
 * We can leverage cookie-based authorization to send the metrics.
 * The auth0.token is sent along with the request, and the API uses it to authorize the request.
 */
function sendMetrics(payload) {
  const url = `${process.env.REACT_APP_PCS_ADMIN_URL}/front-metrics/`;

  const success = navigator.sendBeacon(url, transformPayloadToBlob(payload));

  if (success) {
    console.log('*** METRICS SENT SUCCESSFULLY ***');
  } else {
    console.log('*** METRICS FAILED TO BE SENT ***');
  }
}

function sendOpenMetrics(payload) {
  const url = `${process.env.REACT_APP_PCS_ADMIN_URL}/metrics/open`;

  navigator.sendBeacon(url, transformPayloadToBlob(payload));
}

export const getEventInQueueDuration = (eventId) => {
  const event_in_queue_start = parseInt(
    sessionStorage.getItem(`event_in_queue_start_${eventId}`)
  );
  return event_in_queue_start
    ? Math.round((new Date().getTime() - event_in_queue_start) / 1000)
    : -1;
};

export const createOpenMetricDispatch =
  (dispatch) =>
  ({ metricName, payload }) => {
    const metric = new MetricsModule(metricName);
    dispatch({
      type: 'METRIC',
      payload: {
        ...metric,
        metricName,
        ...payload,
      },
    });
  };

const reducer = (state, action) => {
  switch (action.type) {
    case 'EVENTSCOMING':
      return {
        ...state,
        send: true,
      };
    case 'RESET':
      return {
        send: false,
        payload: {},
      };
    case 'SEND':
      sendMetrics({
        ...action.payload,
        PCSstatus: action.payload.PCSstatus ?? getPCSstatusByLocation(),
      });
      return {
        send: true,
        payload: {},
      };
    case 'METRIC':
      sendOpenMetrics({
        timeseries_index: 'agent-index',
        timestamp_unix: Date.now(),
        data: {
          ...action.payload,
          PCSstatus: action.payload.PCSstatus ?? getPCSstatusByLocation(),
        },
      });
      return {
        send: true,
        payload: {},
      };
  }
};

const MetricsContextProvider = ({ children }) => {
  const state = useReducer(reducer, { send: true, payload: {} });
  return (
    <MetricsContext.Provider value={state}>{children}</MetricsContext.Provider>
  );
};

export default MetricsContextProvider;
