type EventMilestoneData = {
  milestoneName: string;
  eventId: string;
  payload?: any;
};

type AgentMilestoneData = {
  milestoneName: string;
  agentId: string;
  payload?: any;
};

export const useEventMilestone = () => {
  const sendEventMilestone = async (data: EventMilestoneData) => {
    const payload = {
      ...data,
      source: 'agent_portal',
    };

    navigator.sendBeacon(
      `${process.env.REACT_APP_PCS_ADMIN_URL}/metrics/events`,
      JSON.stringify(payload)
    );
  };

  return sendEventMilestone;
};

export const useAgentMilestone = () => {
  const sendAgentMilestone = async (data: AgentMilestoneData) => {
    const payload = {
      ...data,
      source: 'agent_portal',
    };
    navigator.sendBeacon(
      `${process.env.REACT_APP_PCS_ADMIN_URL}/metrics/agents`,
      JSON.stringify(payload)
    );
  };

  return sendAgentMilestone;
};
